import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
	addNewPolicy,
	addNewPolicyError,
	addNewPolicySuccess,
	errorGetInsurerPolicies,
	errorLoadingActivePolicy,
	errorLoadingArchivePolicy,
	errorLoadingPolicyDocumentDownload,
	errorLoadingPremiumRecieptsDownloads,
	errorViewPolicyDetails,
	fetchActivePolicy,
	fetchArchivePolicy,
	fetchGetInsurerPolicies,
	fetchInsurers,
	fetchPolicyDocumentDownload,
	fetchViewPolicyDetails,
	fetchpremiumRecieptsDownloads,
	loadActivePolicy,
	loadArchivePolicy,
	loadGetInsurerPolicies,
	loadInsurers,
	loadPolicyDocumentDownload,
	loadPremiumRecieptsDownloads,
	loadViewPolicyDetails,
} from './Policies.slice';
import { selectIsUserLoggedIn } from '../../Auth/auth.slice';
import {
	AddPolicies,
	GetInsurerPolicies,
	ViewPolicies,
	fetchAllInsurers,
	fetchPolicies,
	fetchPolicyDocument,
	fetchPremiumReceipts,
} from './Policies.service';
import { setAlertInfo } from '../Common/Common.slice';
import { saveBase64AsFile } from '../../../common/utils';
import { openPdf } from '../Common/pdf.slice';

// export enum PolicyStatus {
// 	ACTIVE = 'ACTIVE',
// 	ARCHIVE = 'ARCHIVE',
// }

function* handleFetchActivePolicy(Payload: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		let requestPayload: any;
		if (Payload?.payload?.moduleType) {
			requestPayload = {
				Customer: {
					eiaNo: user.eiaNo,
					policyStatus: 'ACTIVE',
					endoFlag: Payload?.payload?.moduleType,
				},
			};
		} else {
			requestPayload = {
				Customer: {
					eiaNo: user.eiaNo,
					policyStatus: 'ACTIVE',
					endoFlag: '',
				},
			};
		}

		const { data }: any = yield* call(fetchPolicies, requestPayload);
		yield* put(loadActivePolicy(data));
	} catch (e: any) {
		yield* put(errorLoadingActivePolicy(e.message));
	}
}

function* handleFetchArchivePolicy() {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				policyStatus: 'ARCHIVE',
				endoFlag: '',
			},
		};
		const { data }: any = yield* call(fetchPolicies, requestPayload);
		yield* put(loadArchivePolicy(data));
	} catch (e: any) {
		yield* put(errorLoadingArchivePolicy(e.message));
	}
}

function* handleFetchInsurers() {
	try {
		const { data }: any = yield* call(fetchAllInsurers);
		yield* put(loadInsurers(data.MasterData));
	} catch (e: any) {
		yield* put(errorLoadingArchivePolicy(e.message));
	}
}

function* handleFetchStatementOfHolding({ payload }: any) {
	try {
		yield* put(
			setAlertInfo({
				open: true,
				type: 'PROGRESS',
				description: payload.t('PLS_WAIT_DOCUMENT_DOWNLOADING'),
			})
		);
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			Document: {
				holdingStatement: '',
				annualAccountStatement: '',
				policyDocument: '',
				policyNumber: '',
				insurerCode: '',
				alternateCode: '',
				...payload,
			},
		};
		const { data }: any = yield* call(fetchPolicyDocument, requestPayload);
		yield* put(openPdf({ pdfUrl: data.docImageBase64 }));

		const alertInfo: any = {
			open: true,
			title: 'Download Success!',
			type: 'SUCCESS',
			description: `Document has downloaded with file name as ${data.docName} In downloads directory.`,
		};

		yield* put(setAlertInfo(alertInfo));
		const fileName = yield* call(
			saveBase64AsFile,
			data.docImageBase64,
			data.docName
		);
		yield* put(loadPolicyDocumentDownload({ data, fileName }));
	} catch (e: any) {
		yield* put(errorLoadingPolicyDocumentDownload(e.message));
	}
	if (payload?.handleSuccess) {
		payload.handleSuccess();
	}
}

function* handleFetchpremiumRecieptsDownloads({ payload }: any) {
	try {
		yield* put(
			setAlertInfo({
				open: true,
				type: 'PROGRESS',
				description: payload.t('PLS_WAIT_DOCUMENT_DOWNLOADING'),
			})
		);
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				...payload,
			},
		};
		const { data }: any = yield* call(fetchPremiumReceipts, requestPayload);

		const alertInfo: any = {
			open: true,
			title: 'Download Success!',
			type: 'SUCCESS',
			description: `Document has downloaded with file name as ${data.docName} In downloads directory.`,
		};
		yield* put(setAlertInfo(alertInfo));
		const fileName = yield* call(
			saveBase64AsFile,
			data?.docBase64,
			data?.docName
		);
		yield* put(loadPremiumRecieptsDownloads({ data, fileName }));
	} catch (e: any) {
		yield* put(errorLoadingPremiumRecieptsDownloads(e.message));
	}
}

function* handleAddPolicy({ payload }: any) {
	try {
		const policies = payload?.data ?? [];
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
			},
			Policies: policies.map((item: any) => ({
				insurerCode: item?.insurerCode,
				policyNo: item?.policyNo,
				alternateCode: item?.insuranceCode
					? item?.insuranceCode
					: item?.alternateCode,
				requestFrom: item?.requestFrom,
				ackId: item?.ackId || ''
			})),
		};

		const { data }: any = yield* call(AddPolicies, requestPayload);
		yield* put(addNewPolicySuccess(data));

		yield* put(
			setAlertInfo({
				open: true,
				type: 'SUCCESS',
				description: (data?.Policies ? data.Policies : []).map(
					({ displayMessage, errorDescription, policyNo }: any) => ({
						primaryValue: policyNo,
						errorValue: errorDescription,
						successValue: displayMessage,
					})
				),
			})
		);
		if (payload?.handleSuccess) {
			payload.handleSuccess()
		}
		else {
			payload.navigate(-1);
		}
	} catch (e: any) {
		if (e?.Policies) {

			yield* put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: (e?.Policies ? e?.Policies : []).map(
						({ displayMessage, errorDescription, policyNo }: any) => ({
							primaryValue: policyNo,
							errorValue: errorDescription,
							successValue: displayMessage,
						})
					),
				})
			);
			payload.navigate(-1);
		} else if (e?.response?.data) {
			yield* put(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: (e?.response?.data?.Policies
						? e?.response?.data?.Policies
						: []
					).map(({ displayMessage, errorDescription, policyNo }: any) => ({
						primaryValue: policyNo,
						errorValue: errorDescription,
						successValue: displayMessage,
					})),
				})
			);
			const errorCode = e?.response?.data?.Policies.map((policy: any) => policy?.errorCode) || [];
			if (errorCode[0] !== "2121") {
				payload.navigate(-1);
			}

		}

		yield* put(addNewPolicyError(e.message));
	}
}
function* handleFetchViewPolicy({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				policyNo: payload?.policyNum,
				insurerCode: payload?.insCode,
				alternateCode: payload?.altCode,
				verifiedFlag: payload?.VerifiedFlag,
			},
		};
		const { data }: any = yield* call(ViewPolicies, requestPayload);

		yield* put(loadViewPolicyDetails(data));
	} catch (e: any) {
		yield* put(errorViewPolicyDetails(e.message));
	}
}


function* handleGetInsurerPolicies({ payload }: any) {
	try {
		const user: any = yield* select(selectIsUserLoggedIn);
		const requestPayload = {
			Customer: {
				eiaNo: user.eiaNo,
				"policyStatus": "ACTIVE",
				"endoFlag": ""
			},
		};
		const { data }: any = yield* call(GetInsurerPolicies, requestPayload);

		yield* put(loadGetInsurerPolicies(data));
	} catch (e: any) {
		yield* put(errorGetInsurerPolicies(e.message));
	}
}
function* watchPolicies() {
	yield* takeEvery(fetchActivePolicy.type, handleFetchActivePolicy);
	yield* takeEvery(fetchArchivePolicy.type, handleFetchArchivePolicy);
	yield* takeEvery(
		fetchPolicyDocumentDownload.type,
		handleFetchStatementOfHolding
	);
	yield* takeEvery(fetchInsurers.type, handleFetchInsurers);
	yield* takeEvery(addNewPolicy.type, handleAddPolicy);
	yield* takeEvery(fetchViewPolicyDetails.type, handleFetchViewPolicy);
	yield* takeEvery(
		fetchpremiumRecieptsDownloads.type,
		handleFetchpremiumRecieptsDownloads
	);
	yield* takeEvery(fetchGetInsurerPolicies.type, handleGetInsurerPolicies);
}

export default watchPolicies;
