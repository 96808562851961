import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { find, propEq } from 'ramda';

import CustomButton from '../../../common/ui/CustomButton';
import { ReactComponent as rightArrow } from '../../../common/icons/right_arrow-icon.svg';
import ManageAddressHeader from './ManageAddressHeader';
import './Address.scss';
import {
	changeAddress,
	fetchPoliciesForAddress,
	getAddressEkycUrlRequest,
	removeAddress,
	resetPermanentAddress,
	saveAddressEkycRequest,
	// saveAddressEkycRequest,
	selectAddressChangesList,
	selectGetAddressEkycUrl,
	selectIsPolicyAddressLoading,
	selectIsUpdateAddressLoading,
	selectPoliciesForAddress,
	selectSaveAddressKYCIsLoading,
	selectekycAddress,
	setAddressChangesList,
	updateAddressChangesRequest,
} from './Address.slice';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { SELECT_POLICY_TYPES_FOR_MODULES } from '../../../common/Enums';
import Spinner from '../../../common/ui/Spinner';

import DoneModal from '../Common/DoneModal';
import ConfirmationModal from '../../../common/ui/Modal/ConfirmationModal';
import RemoveAddressModal from './AddressModals/RemoveAddressModal';
import AddressEntryModal from './AddressModals/AddressEntryModal';
// import { createEkycPopup } from '../../../common/utils';
import Separator from '../../../common/ui/Separator';
import ChangeAddressModal from './AddressModals/ChangeAddressModal';
import ApiModal from '../../../common/ui/Modal/ApiModal';
import cryptoEncryptionDecryption from '../../../common/crypto';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import 'firebase/analytics';


const ManageAddressScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const theme = useTheme();
	const { t } = useTranslation();

	const ekyc = location.state?.ekycId;
	const familyAddAddress = location.state?.type;
	const ekycResponse = useSelector(selectGetAddressEkycUrl)
	const ekycAddress = useSelector(selectekycAddress);
	const customerName = window.sessionStorage.getItem('customerName' || "") || "";
	const trimmedCustomerName = customerName
		? cryptoEncryptionDecryption
			.Decrypt(customerName || "")
			.replace(/^"(.*)"$/, '$1')
			.trimEnd()
		: '';

	const [showAddModal, setShowAddModal] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [isEkycPopupOpen, setIsEkycPopupOpen] = useState(false);
	const [showDoneModal, setShowDoneModal] = useState(false);
	const [showRemoveNumberModal, setShowRemoveNumberModal] = useState(false);
	const [showChangeNumberModal, setShowChangeNumberModal] = useState(false);
	const [selectedPolicyDetails, setSelectedPolicyDetails] = useState({});
	const [removeUnAssignedAddress, setRemoveUnAssignedAddress] =
		useState<any>(false);
	const [isPreSelectedPolicyType, setIsPreSelectedPolicyType] =
		useState<any>(false);
	const addressChangesList = useSelector(selectAddressChangesList);
	const { addressBasedPolicies, unassignedAddresses }: any = useSelector(
		selectPoliciesForAddress
	);
	useEffect(() => {
		if (familyAddAddress) {
			setShowAddModal(true);
		}
	}, [familyAddAddress]);

	useEffect(() => {
		if (addressChangesList?.length === 0) {
			dispatch(fetchPoliciesForAddress());
		}
	}, [dispatch, addressChangesList]);
	useEffect(() => {
		const customerNamee = ekycResponse?.customerName ? cryptoEncryptionDecryption.Encrypt(ekycResponse?.customerName) : '';
		!customerName.length ? window.sessionStorage.setItem(
			'customerName', customerNamee
		) : "";
	}, [ekycResponse]);

	const handleChangeAddress = (
		selectedAddress: string,
		isNewAddress?: boolean
	) => {
		debugger;
		let newAddress: any = {};

		if (isNewAddress) {
			newAddress = selectedAddress;
		} else {
			newAddress = find(propEq(selectedAddress, 'fullAddress'))([
				...addressBasedPolicies,
				...unassignedAddresses,
			]);
		}

		dispatch(
			changeAddress({
				selectedPolicy: selectedPolicyDetails,
				newAddress,
			})
		);
		setShowChangeNumberModal(false);
	};

	const handleRemoveAddress = (selectedAddress: any, isNewAddress: boolean) => {
		let newAddress: any = {};
		if (isNewAddress) {
			newAddress = selectedAddress;
		} else {
			newAddress = find(propEq(selectedAddress, 'fullAddress'))([
				...addressBasedPolicies,
				...unassignedAddresses,
			]);
		}
		dispatch(
			removeAddress({
				oldAddress: selectedPolicyDetails,
				newAddress,
			})
		);
		setShowRemoveNumberModal(false);
	};

	const toggleAddNumberModal = (
		isPoliciesPreSelected: boolean,
		isChange: boolean
	) => {
		if (isPoliciesPreSelected === true) {
			setIsPreSelectedPolicyType(isChange ? 'CHANGE' : 'REMOVE');
		} else {
			setIsPreSelectedPolicyType(false);
		}
		setShowAddModal(!showAddModal);
		setShowRemoveNumberModal(false);
		setShowChangeNumberModal(false);
	};
	const toggleDoneModal = () => {
		setShowDoneModal(!showDoneModal);
	};

	const toggleRemoveNumberModal = (address: any) => {
		setSelectedPolicyDetails(address);
		setShowRemoveNumberModal(!showRemoveNumberModal);
	};

	const handleOnChangeAddressClick = (data: any) => {
		setSelectedPolicyDetails(data || {});
		setShowChangeNumberModal(!showChangeNumberModal);
	};

	const handleNewAddressSubmit = (data: any) => {
		setShowAddModal(false);
		if (isPreSelectedPolicyType === 'CHANGE') {
			handleChangeAddress(data, true);
			setIsPreSelectedPolicyType(false);
		} else if (isPreSelectedPolicyType === 'REMOVE') {
			handleRemoveAddress(data, true);
			setIsPreSelectedPolicyType(false);
		} else {
			navigate(APP_ROUTES.ASSIGN_POLICY, {
				state: {
					newAddress: data,
					MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS,
				},
			});
		}
	};

	const handleAddressChangesSubmit = () => {
		const handleDone: any = () => {
			setShowDoneModal(false);
			navigate(APP_ROUTES.DASHBOARD);
		};
		console.log("ekyc", ekyc)
		if (ekyc) {
			const payload = {
				ekycId: ekyc, handleDone, customerName: trimmedCustomerName
			};
			dispatch(saveAddressEkycRequest(payload as any));
		}
		if (addressChangesList?.length > 0) {
			dispatch(updateAddressChangesRequest({ handleDone, t } as any));
		}
	};

	const closeAllModals = () => {
		setShowAddModal(false);
		setShowDoneModal(false);
		setShowRemoveNumberModal(false);
		setShowChangeNumberModal(false);
	};

	const isPoliciesForAddressLoading = useSelector(selectIsPolicyAddressLoading);

	const handleAssignPolicies = (address: any) => {
		navigate(APP_ROUTES.ASSIGN_POLICY, {
			state: {
				newAddress: address,
				isOld: true,
				MODULE_TYPE: SELECT_POLICY_TYPES_FOR_MODULES.ADDRESS,
			},
		});
	};

	const handleRemoveForUnAssignedAddress = () => {
		const handleDone = () => {
			setRemoveUnAssignedAddress(false);
		};
		const payload = {
			oldAddress: removeUnAssignedAddress,
			isUnAssignedAddress: true,
			handleDone,
		};
		dispatch(removeAddress(payload));
	};

	const handleDonePress = () => {
		if (addressChangesList?.length > 0 || ekycAddress?.pincode?.length) {
			toggleDoneModal();
		} else if (ekyc || localStorage.getItem('ekcy') === 'ekcy') {
			navigate(APP_ROUTES.DASHBOARDSCREEN);
		} else {
			navigate(-1);
		}
		return true;
	};
	const handleCancelChanges = () => {
		navigate(APP_ROUTES.DASHBOARDSCREEN);
		dispatch(setAddressChangesList([]));
		dispatch(resetPermanentAddress());
	};

	const isUpdateAddressLoading = useSelector(selectIsUpdateAddressLoading);
	const isSaveKYCIsLoading = useSelector(selectSaveAddressKYCIsLoading);

	// const handleEkycFeedBack = () => {
	// 	setIsEkycPopupOpen(false);
	// 	dispatch(saveAddressEkycRequest());
	// };

	const handleChangePermanentAddress = () => {
		localStorage.setItem('ekcy', 'ekcy');
		const handleSuccess = (data: any) => {
			// createEkycPopup(data?.url, handleEkycFeedBack);
			// setIsEkycPopupOpen(true);
			window.location.href = data?.url;
		};
		dispatch(getAddressEkycUrlRequest({ handleSuccess } as any));
	};

	useEffect(() => {
		const analytics = getAnalytics();
		logEvent(analytics, 'page_view', {
			page_location: window.location.href,
			page_path: location.pathname,
			page_title: 'Manage Address Screen',
		});
	}, []);

	return (
		<Box marginBottom={5} className="ml-50 mr-50">
			<div className="fixed-policy-header">
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography className="f-16 fw-600 " color="primary">
							{t('MANAGE_ADDRESS')}
						</Typography>
					</Grid>

					<Grid item className="">
						<CustomButton
							text={t('DONE')}
							variant="text"
							color="primary"
							showIcon
							fontSize={14}
							onClick={handleDonePress}
							endIcon={rightArrow}
						/>
					</Grid>
				</Grid>
				<Grid item className="mt-10">
					<Separator color={theme.palette.primary.main} borderWidth={1} />
				</Grid>
			</div>
			<div
				style={{
					marginTop: 45,
				}}
			/>

			{isPoliciesForAddressLoading ? (
				<Grid container justifyContent="center">
					<Grid item>
						<Spinner />
					</Grid>
				</Grid>
			) : (
				<ManageAddressHeader
					handleOnChangeAddressClick={handleOnChangeAddressClick}
					handleAssignPolicies={handleAssignPolicies}
					setRemoveUnAssignedAddress={setRemoveUnAssignedAddress}
					toggleRemoveNumberModal={toggleRemoveNumberModal}
					toggleAddNumberModal={toggleAddNumberModal}
					handleChangePermanentAddress={handleChangePermanentAddress}
					isEkycPopupOpen={isEkycPopupOpen}
				/>
			)}
			<DoneModal
				open={showDoneModal}
				setOpen={setShowDoneModal}
				onSubmit={handleAddressChangesSubmit}
				loading={isUpdateAddressLoading || isSaveKYCIsLoading}
				onCancel={handleCancelChanges}
				contentType="ADDRESS"
			/>
			<ChangeAddressModal
				open={showChangeNumberModal}
				setOpen={setShowChangeNumberModal}
				selectedPolicyDetails={selectedPolicyDetails}
				onSubmit={handleChangeAddress}
				toggleAddNumberModal={toggleAddNumberModal}
			/>
			<RemoveAddressModal
				open={showRemoveNumberModal}
				setOpen={setShowRemoveNumberModal}
				selectedPolicyDetails={selectedPolicyDetails}
				onSubmit={handleRemoveAddress}
				toggleAddNumberModal={toggleAddNumberModal}
			/>
			<AddressEntryModal
				show={showAddModal}
				setShow={setShowAddModal}
				onSubmit={handleNewAddressSubmit}
				handleCancel={closeAllModals}
			/>
			<ConfirmationModal
				show={Boolean(removeUnAssignedAddress)}
				setShow={setRemoveUnAssignedAddress}
				description={`${t(
					'ARE_YOU_SURE_TO_REMOVE'
				)} ${removeUnAssignedAddress?.fullAddress} 
				${`${t('FROM_UNASSIGNED_ADDRESSES')}?`}`}
				confirmText="Remove"
				cancelText="Cancel"
				onConfirm={handleRemoveForUnAssignedAddress}
				onCancel={() => setRemoveUnAssignedAddress(false)}
			/>
			<ApiModal />
		</Box>
	);
};

export default ManageAddressScreen;
