import React, { useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as cancel } from '../../../../common/icons/cancel-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
// import HdfcIcon from '../../../../common/icons/update-mobile/hdfc_bank_icon.png';
import { ReactComponent as MobileIcon } from '../../../../common/icons/update-mobile/camera_icon.svg';
import { ReactComponent as BackSlashIcon } from '../../../../common/icons/update-mobile/backSlash_icon.svg';
import { ReactComponent as UploaderIcon } from '../../../../common/icons/update-mobile/Uploader_icon.svg';
import { ReactComponent as SkipIcon } from '../../../../common/icons/update-mobile/skip_icon.svg';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import ImagePickerModal from '../../../../common/ui/Modal/ImagePickerModal';
import { appendBase64PrefixForImage } from '../../../../common/utils';
import { useSelector } from 'react-redux';
import { selectTotalPolices } from '../Bank.slice';

const UploadImageBankModal = ({
	skipforNowButton,
	open,
	setOpen,
	bankDetails,
	accountDetails,
	handleUploadModal,
	uploadChequeIsDone,
	uploadChequeData,
	handleSkip,
	imagePickerModalVisible,
	setImagePickerModalVisible,
	handleAssignPolicies,
	showAssignPolicies,
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const policiesForBank = useSelector(selectTotalPolices);

	const anyIntegPolicies = useMemo(() =>
		policiesForBank.some((item: any) => item.insIntegFlag === 'Y')
		, [policiesForBank]);

	return (
		<>
			<CommonModal
				onClose={() => setOpen(true)}
				open={open}
				modalClassName="px-20 py-20"
				boxProps={{ width: 354 }}
			>
				<Grid container justifyContent="center">
					<Grid item className="assign-policy">
						{/* <Grid item className="  mb-10" textAlign="center">
							<img src={HdfcIcon} alt="logo" width="73" height="77" />
						</Grid> */}
						<Grid item textAlign="center">
							<Grid item>
								<Grid item>
									<Typography
										className="f-16 fw-600"
										color={theme.palette.primary.main}
									>
										{bankDetails?.BANK}
									</Typography>
									<Typography
										className="f-16 fw-600"
										color={theme.palette.common.black}
									>
										{accountDetails?.accountHolderName}
									</Typography>

									<Typography
										className="f-16"
										color={theme.palette.common.black}
									>
										{t('ACC')}# {accountDetails?.accountNumber}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										className="f-16"
										color={theme.palette.common.black}
									>
										{bankDetails?.BANK}
									</Typography>
									<Typography
										className="f-16"
										color={theme.palette.common.black}
									>
										{bankDetails?.BRANCH}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										className="f-16"
										color={theme.palette.common.black}
									>
										{bankDetails?.IFSC}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						{uploadChequeIsDone ? (
							<>
								<Grid>
									<Grid>
										<img
											src={appendBase64PrefixForImage(
												uploadChequeData?.chequeImage
											)}
											alt="cheque"
											style={{
												width: '100%',
												height: 150,
												objectFit: 'contain',
											}}
										/>
									</Grid>
								</Grid>
							</>
						) : (
							<>
								<Grid item className="mt-10">
									<Typography
										className="f-16 fw-600"
										color={theme.palette.primary.main}
										textAlign="center"
									>
										{t('UPLOAD_IMAGE_CANCELLED_CHEQUE.')}
									</Typography>

									<Typography
										className="f-12 my-10"
										color={theme.palette.grey[400]}
										textAlign="center"
									>
										{t('GOVT_REGULATIONS_CANCELLED_CHEQUE_IS_MANDATORY.')}
									</Typography>
								</Grid>
							</>
						)}

						<Grid item className="mt-20">
							<Grid
								container
								spacing={0.5}
								alignItems="center"
								direction="column"
							>
								{uploadChequeIsDone ? (
									<>
										{!showAssignPolicies && (
											<Grid item className="">
												<CustomButton
													text={t('ASSIGN_POLICIES')}
													variant="text"
													color="primary"
													startIcon={cancel}
													showIcon={false}
													onClick={handleAssignPolicies}
													type="submit"
													fontWeight={600}
													fontSize={14}
													disabled={!anyIntegPolicies && process.env.REACT_APP_MODE !== 'PROD'}
												/>
											</Grid>
										)}
										{
											process.env.REACT_APP_MODE !== 'PROD' && !anyIntegPolicies && !anyIntegPolicies &&
											<Grid container justifyContent={'center'} textAlign={'center'}>
												<Typography className='f-12' color={theme.palette.grey[400]}>This service is not currently available for your insurer’s policy(s).</Typography>
											</Grid>
										}
									</>
								) : (
									<>
										<Grid item className="">
											<Grid container flexWrap="nowrap">
												<Grid item>
													<SvgConverter
														Icon={MobileIcon}
														width="22"
														height="20"
													/>
												</Grid>
												<Grid item>
													<SvgConverter
														Icon={BackSlashIcon}
														width="12"
														height="24"
														className="image-upload-modal ml-7"
													/>
												</Grid>
												<Grid item>
													<SvgConverter
														Icon={UploaderIcon}
														width="22"
														height="22"
														className="ml-n2"
													/>
												</Grid>
												<Grid item className="">
													<CustomButton
														text={t('TAKE_A_PIC_BROWSE')}
														variant="text"
														color="primary"
														showIcon={false}
														onClick={() => setImagePickerModalVisible(true)}
														type="submit"
														fontWeight={600}
														fontSize={14}
													/>
												</Grid>
											</Grid>
										</Grid>
									</>
								)}
								<Grid item className="">
									{skipforNowButton ? (
										<>
											<CustomButton
												text={t('SKIP_FOR_NOW')}
												variant="text"
												color="primary"
												startIcon={SkipIcon}
												showIcon={false}
												onClick={() => handleSkip(uploadChequeIsDone)}
												// onClick={() => setOpen(false)}
												type="submit"
												fontWeight={600}
												fontSize={14}
											/>
										</>
									) : (
										<>
											<CustomButton
												text={t('SKIP_FOR_NOW')}
												variant="text"
												color="primary"
												startIcon={SkipIcon}
												showIcon={false}
												onClick={() => uploadChequeIsDone ? handleSkip(uploadChequeIsDone) : setOpen(false)}
												// onClick={() => setOpen(false)}
												type="submit"
												fontWeight={600}
												fontSize={14}
											/>
										</>
									)}
								</Grid>
								<Grid item className="">
									<CustomButton
										text={t('CANCEL')}
										variant="text"
										color="primary"
										startIcon={cancel}
										showIcon={false}
										onClick={() => setOpen(false)}
										type="submit"
										fontWeight={400}
										fontSize={14}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</CommonModal>
			<ImagePickerModal
				open={imagePickerModalVisible}
				setOpen={setImagePickerModalVisible}
				handleImage={handleUploadModal}
			/>
		</>
	);
};
export default UploadImageBankModal;
