import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomRadioGroup from '../../../../common/ui/Forms/FormInput/RadioButton/RadioGroup';
import CommonModal from '../../../../common/ui/Modal/CommonModal';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import { ReactComponent as AddMobileIcon } from '../../../../common/icons/update-mobile/Add_Mobile.svg';
import { ReactComponent as Cancel } from '../../../../common/icons/update-mobile/cancel_orange.svg';
import { ReactComponent as Verify } from '../../../../common/icons/update-mobile/verify_orange.svg';
import {
	changeMobileNumber,
	changePrimaryNumber,
	getMobileNoList,
	selectPoliciesForMobileNumbers,
} from '../MobileNumber.slice';
import { setAlertInfo } from '../../Common/Common.slice';
import UnverifiedText from '../UnverifiedText';

const ChangeNumberModal = ({
	open,
	setOpen,
	selectedPolicyDetails,
	toggleAddNumberModal,
	showAddButton,
	isPrimaryChange = false,
	handleInstantVerify
}: any) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const [selectedOption, setSelectedOption] = React.useState<any>(false);
	const mobilePolicies = useSelector(selectPoliciesForMobileNumbers);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!open) setSelectedOption(false);
	}, [open]);

	const mobileNumbers = useMemo(
		() =>
			getMobileNoList(selectedPolicyDetails, mobilePolicies).map((val) => ({
				value: val.mobileNo,
				label: (<Grid container alignItems={'center'}>
					{val.mobileNo} &nbsp;
					{String(val.mobileNoVerified).toUpperCase() !== 'Y' && (
						<UnverifiedText />
					)}
				</Grid>),
				mobileNoVerified: val.mobileNoVerified
			})),
		[
			mobilePolicies.mobileBasedPolicies,
			mobilePolicies.unAssignedMobiles,
			selectedPolicyDetails?.mobileNo,
		]
	);
	const handleSubmit = () => {
		if (selectedOption) {
			if (mobileNumbers.filter((item: any) => item.value === selectedOption)?.[0].mobileNoVerified === 'Y') {
				if (!isPrimaryChange) {
					dispatch(
						changeMobileNumber({
							selectedPolicy: selectedPolicyDetails,
							targetMobileNo: selectedOption,
						})
					);
				} else {
					dispatch(
						changePrimaryNumber({
							selectedNumber: selectedPolicyDetails?.mobileNo,
							targetedNumber: selectedOption,
						})
					);
				}
			}
			else {
				handleInstantVerify(selectedOption)
			}
		} else {
			dispatch(
				setAlertInfo({
					open: true,
					type: 'FAILED',
					description: t('PLEASE_SELECT_MOBILE'),
				})
			);
		}
		setOpen(false);
	};

	const handleOptionChange = (value: string) => {
		setSelectedOption(value);
	};

	return (
		<CommonModal
			onClose={() => setOpen(true)}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 354 }}
		>
			<Grid item>
				<Grid item>
					{mobileNumbers.length !== 0 && <>
						<Typography
							className="f-16 fw-600"
							color={theme.palette.primary.main}
						>
							{isPrimaryChange
								? t('SELECT_PHONE_NUMBER_PRIMARY')
								: t('SELECT_A_NUMBER_TO_ASSIGN')}
						</Typography>
						{mobileNumbers.length !== 0 && (
							<Grid container>
								<Grid item xs={12} className="my-25">
									<CustomRadioGroup
										name="RadioGroup"
										options={mobileNumbers}
										value={selectedOption}
										onChange={handleOptionChange}
										size="small"
									/>
								</Grid>
								{showAddButton && (
									<Grid item xs={12} className="py-2">
										<Typography
											className="f-16 fw-600"
											color={theme.palette.primary.main}
										>
											{t('OR')}
										</Typography>
									</Grid>
								)}
							</Grid>
						)} </>}
					{showAddButton && (
						<Grid item className="py-10">
							<CustomButton
								text={t('ADD_A_MOBILE_NUMBER')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={() =>
									toggleAddNumberModal(true, true, isPrimaryChange)
								}
								fontSize={14}
								startIcon={AddMobileIcon}
							/>
						</Grid>
					)}

					<Grid item className="py-5">
						<Separator color={theme.palette.primary.main} borderWidth={1} />
					</Grid>
					<Grid container textAlign="center">
						<Grid item xs={12} className="py-10 ">
							<CustomButton
								text={t('CHANGE_NUMBER')}
								variant="text"
								color="primary"
								showIcon={false}
								onClick={handleSubmit}
								fontSize={16}
								fontWeight={600}
								startIcon={Verify}
								disabled={!mobileNumbers.length}
							/>
						</Grid>
						<Grid item xs={12} className="py-10">
							<CustomButton
								text={t('CANCEL')}
								variant="text"
								color="primary"
								showIcon={false}
								startIcon={Cancel}
								onClick={() => setOpen(false)}
								fontSize={16}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</CommonModal>
	);
};

export default ChangeNumberModal;
